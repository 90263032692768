import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1048.000000) scale(0.100000,-0.100000)">
        <path d="M1360 5240 l0 -3890 3883 2 3882 3 0 3885 0 3885 -3882 3 -3883 2 0
-3890z m7600 0 l0 -3720 -3720 0 -3720 0 0 3720 0 3720 3720 0 3720 0 0 -3720z"/>
<path d="M4735 7964 c-360 -15 -664 -149 -915 -403 -213 -215 -338 -486 -370
-800 -5 -57 -10 -288 -9 -515 1 -398 2 -407 15 -299 31 258 103 502 216 733
77 156 122 229 238 385 78 104 283 318 360 376 25 18 47 36 50 40 16 21 202
145 295 197 242 135 508 227 773 266 51 7 94 15 97 17 4 5 -648 7 -750 3z"/>
<path d="M5510 7811 c-52 -4 -103 -11 -112 -17 -10 -6 165 -9 480 -9 273 0
515 -4 537 -9 80 -18 159 -63 233 -134 40 -38 72 -76 72 -85 0 -9 6 -22 13
-29 31 -31 48 -70 66 -150 11 -51 15 -91 10 -99 -5 -8 -9 -44 -9 -81 0 -37 -4
-70 -10 -73 -5 -3 -10 -19 -10 -34 0 -16 -18 -55 -40 -89 -23 -37 -34 -62 -27
-62 18 0 57 38 57 55 0 7 11 22 25 33 14 11 25 27 25 35 0 8 6 20 14 26 29 24
51 122 51 226 0 102 -14 168 -46 215 -9 14 -19 35 -23 46 -14 45 -152 167
-221 196 -41 17 -80 28 -125 36 -44 7 -862 10 -960 3z m1230 -186 c0 -8 -4
-17 -10 -20 -6 -4 -10 5 -10 20 0 15 4 24 10 20 6 -3 10 -12 10 -20z"/>
<path d="M5265 7770 c-18 -8 -18 -9 5 -9 14 0 32 4 40 9 12 8 12 10 -5 9 -11
0 -29 -4 -40 -9z"/>
<path d="M5208 7753 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6635 6880 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M5745 6355 c-382 -89 -696 -335 -868 -680 -46 -90 -100 -240 -112
-305 -19 -108 -26 -176 -23 -223 l3 -52 575 0 c529 0 581 1 645 19 397 105
600 546 420 911 -84 170 -234 295 -409 340 -75 20 -112 18 -231 -10z"/>
<path d="M4773 6243 c-122 -2 -193 -7 -193 -14 0 -6 57 -9 159 -9 153 2 180
-2 218 -32 15 -12 0 -59 -27 -83 -10 -9 -16 -23 -13 -31 3 -8 -1 -14 -10 -14
-8 0 -25 -12 -38 -26 -13 -15 -34 -37 -46 -49 -13 -12 -23 -27 -23 -33 0 -6
-9 -17 -19 -24 -11 -8 -23 -26 -26 -41 -4 -15 -16 -32 -26 -38 -11 -5 -19 -18
-19 -28 0 -10 -9 -30 -20 -44 -11 -14 -20 -31 -20 -39 0 -7 -9 -24 -20 -38
-11 -14 -20 -33 -20 -42 0 -9 -4 -19 -8 -22 -5 -3 -17 -40 -26 -83 -10 -43
-24 -94 -31 -113 -39 -97 -54 -267 -54 -637 -1 -181 3 -283 9 -283 12 0 10
-79 -2 -86 -5 -3 -9 -36 -9 -72 1 -37 1 -80 1 -97 -1 -16 1 -49 2 -73 2 -23
-1 -43 -6 -45 -5 -2 -6 -9 -2 -15 5 -9 13 -9 27 -2 15 9 19 7 19 -5 0 -12 -15
-19 -40 -17 -3 0 -6 -32 -7 -71 -4 -102 -6 -120 -20 -135 -15 -15 -17 -32 -5
-32 5 0 14 -10 20 -22 10 -22 11 -22 11 -2 1 11 -4 25 -11 32 -7 7 -8 12 -2
12 5 0 15 -5 20 -10 17 -17 56 75 65 154 4 39 8 287 9 551 0 264 5 543 11 620
6 77 11 149 11 159 -1 11 5 31 13 45 15 29 55 155 55 173 0 6 9 30 19 52 30
62 54 119 57 135 1 7 14 26 28 40 14 15 26 35 26 45 0 10 10 29 23 42 46 49
58 65 51 75 -3 6 3 13 15 16 12 3 21 9 21 14 0 6 27 37 60 70 33 33 60 64 60
69 0 6 4 10 9 10 5 0 25 16 44 35 19 19 40 35 46 35 6 0 11 4 11 9 0 6 -8 9
-17 9 -10 -1 -52 1 -93 4 -41 2 -162 3 -267 1z m-93 -579 c0 -8 -4 -12 -10 -9
-5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-33 -86 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-92 -1074 c-4 -65 -15 -86 -15 -28 0 37
9 84 15 84 2 0 2 -25 0 -56z m5 -84 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3
6 10 10 16 10 5 0 9 -4 9 -10z m-24 -177 c-14 -14 -23 9 -13 32 l12 25 3 -26
c2 -14 1 -28 -2 -31z"/>
<path d="M6705 3091 c-71 -18 -90 -39 -149 -171 -22 -47 -59 -128 -83 -180
-48 -103 -93 -206 -93 -215 0 -3 38 -5 85 -5 l84 0 20 45 20 45 170 0 169 0
18 -45 17 -45 83 0 c48 0 84 4 84 10 0 9 -151 342 -208 459 -17 33 -40 62 -62
77 -39 25 -109 37 -155 25z m117 -243 c25 -57 44 -106 41 -110 -7 -13 -213
-10 -213 3 0 13 70 179 83 197 24 31 44 11 89 -90z"/>
<path d="M7934 3089 c-67 -16 -93 -54 -213 -316 -61 -133 -111 -244 -111 -247
0 -3 41 -6 90 -6 75 0 90 3 90 15 0 9 7 29 15 45 l16 30 170 0 170 0 19 -45
19 -45 85 0 c100 0 98 -18 17 153 -28 59 -51 109 -51 112 0 15 -124 256 -140
270 -22 20 -86 44 -115 43 -11 0 -38 -4 -61 -9z m116 -230 c63 -142 68 -129
-54 -129 -58 0 -107 4 -110 9 -5 8 12 52 65 169 30 67 52 56 99 -49z"/>
<path d="M5755 3078 c-3 -7 -4 -135 -3 -283 l3 -270 210 0 c241 0 273 7 342
76 60 59 86 120 87 199 0 114 -66 216 -167 261 -48 21 -70 23 -259 27 -170 3
-209 2 -213 -10z m421 -162 c63 -38 83 -120 44 -184 -30 -50 -71 -62 -205 -62
l-115 0 0 128 c0 71 3 132 8 136 4 5 59 6 122 4 88 -3 122 -8 146 -22z"/>
<path d="M7047 3083 c-4 -3 -7 -37 -7 -74 l0 -68 128 -3 127 -3 3 -207 2 -208
75 0 75 0 0 210 0 210 125 0 125 0 0 75 0 75 -323 0 c-178 0 -327 -3 -330 -7z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
